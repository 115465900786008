import React from 'react';
import Helmet from 'react-helmet';
import '../styles/materialize.css';
import '../styles/global.less';

const Common = () => (
    <Helmet>
        <link href="https://fonts.googleapis.com/css?family=Fira+Sans:300,400,500,800" rel="stylesheet"/>
         <link href="https://fonts.googleapis.com/css?family=Montserrat:300,400,500,800" rel="stylesheet"/>
        <script src="https://use.fontawesome.com/43b9b6d414.js" />
    </Helmet>
)

export default Common;
